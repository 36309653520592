@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.body {
	width: 100%;
	overflow: hidden;
	min-height: 100vh;
	position: relative;
}

#mainContent {
	width: 100%;
	height: 100%;
}

.mainContent {
	position: relative;
	padding-top: 20px;

	@include tablet() {
		padding-left: 190px;
	}

	&.mainContentBig {
		padding-left: 70px;
	}
}

.logo {
	text-indent: -9999px;
	grid-column: 2 / span 4;
	overflow: hidden;
	width: 100%;
	max-width: 100%;
	height: 80px;
	background-image: url(/images/logo.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto 100%;
	position: relative;
	margin-top: 3vh;
	margin-bottom: 0px;
	margin-left: auto;
	margin-right: auto;

	@include respond-min($sm) {
		background-size: 100% auto;
		margin-top: 6vh;
		grid-column: 5 / span 4;
		margin-bottom: 20px;
		width: 80%;
	}

	@include respond-min($md) {
		width: 100%;
		margin-top: 8vh;
		grid-column: 6 / span 2;
	}
}

.introCont {
	text-align: center;
	grid-column: 1 / span 6;
	background-color: $color-lightest;
	border-radius: 10px;
	padding: 30px 20px;

	@include respond-min($sm) {
		grid-column: 4 / span 6;
	}

	@include desktop() {
		grid-column: 5 / span 4;
	}
}

.orSalesCont {
	text-align: center;
	grid-column: 1 / span 6;

	@include respond-min($sm) {
		grid-column: 4 / span 6;
	}

	@include respond-min($md) {
		grid-column: 5 / span 4;
	}
}

div.toast {
	border-radius: 0;
	color: $color-lightest;
	@include font-size(20);
	padding: 10px 20px;
	background-color: $color-tertiary;
}
