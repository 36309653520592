@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;
@use "sass:color";

.button {
	border-radius: 5px;
	font-weight: 500;
	@include font-size(18);
	color: $color-darkest;
	text-align: center;
	padding: 8px 10px;
	width: 100%;
	transition:
		color 0.4s,
		background-color 0.4s,
		box-shadow 0.4s;
	border: 2px solid $color-primary;
	margin-bottom: 20px;
	display: inline-block;
	line-height: 1.5;
	background-size: center;

	&:disabled {
		opacity: 0.5;
	}

	@include respond-min($md) {
		padding: 8px 20px;
	}

	&:hover {
		text-decoration: none;
	}

	&.full {
		width: 100%;
	}

	&.relative {
		width: auto;
		white-space: nowrap;
	}

	&.is-loading {
		background-image: url(/images/loading.gif);
		background-repeat: no-repeat;
		background-size: 20px auto;
		background-position: center;
		text-indent: -9999px;
	}
}

.primary {
	@extend .button;
	background-color: $color-primary;

	@include respond-min($lg) {
		&:hover {
			background-color: color.scale($color-primary, $lightness: 5%);
			box-shadow: $shadow;
		}
	}

	&.tiny {
		@include font-size(14);
		padding: 3px 10px;
	}

	&.small {
		@include font-size(16);
		padding: 5px 15px;
	}

	&.is-loading {
		text-indent: 0;
		color: $color-primary;
	}
}

.formButton {
	margin-top: 10px;
}

.secondary {
	@extend .button;
	background-color: $color-lightest;

	&.small {
		@include font-size(14);
		padding: 2px 5px;
		margin: 0px 5px 5px 0px;
	}

	@include respond-min($lg) {
		&:hover {
			box-shadow: $shadow;
			border-color: color.scale($color-primary, $lightness: 5%);
		}
	}
}

.tertiary {
	@extend .button;
	background-color: $color-secondary;
	border-color: $color-secondary;
	color: $color-lightest;

	&:hover {
		box-shadow: $shadow;
	}
}

.negative {
	@extend .button;
	background-color: $color-error;
	border-color: $color-error;
	color: $color-lightest;

	&:hover {
		box-shadow: $shadow;
	}
}

.text {
	@include font-size(18);
	font-weight: 500;
	white-space: nowrap;

	&.tiny {
		@include font-size(14);
	}

	&.small {
		@include font-size(16);
	}

	svg {
		width: 24px;
		height: 24px;
		position: relative;
		top: 6px;
		margin-right: 5px;
		path {
			fill: $color-darkest;
		}
	}
}
